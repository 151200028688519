<template>
  <div>
    <section class="filter-form">
      <TypePicker
        :TypeMaps="typePickerStore.typePickerData.TypeMaps"
        :handleChangeType="handleChangeType"
        :handlePickType="handlePickType" 
        :clearTypes="handleSelectAllTypes"
        />
      <RegionPicker
        :handleCityChange="handleCityChange"
        :cityVal="needStore.needStoreData.address" />
    </section>
    <section class="filter-form current-filter">
      <CurrentFilter
        :currentFilteredType="typePickerStore.typePickerData.currentTypeName"
        :reselect="clearType" />
    </section>
  </div>
</template>

<script>
  import RegionPicker from '@/components/filterForm/regionPicker'
  import TypePicker from '@/components/filterForm/typePicker'
  import CurrentFilter from '@/components/filterForm/currentFilter'
  import { useNeedStore } from '@/store/needStore'
  import { useTypePickerStore } from '@/store/needStore/typePickerStore'
  export default {
    components: {
      RegionPicker,
      TypePicker,
      CurrentFilter,
    },
    setup() {
      const needStore = useNeedStore()
      const { handleCityChange } = useNeedStore()
      const typePickerStore = useTypePickerStore()
      const { clearType, handleDeleteType, handlePickType,handleSelectAllTypes } = useTypePickerStore()
      const { handleChangeType } = useTypePickerStore()

      return {
        handleCityChange,
        typePickerStore,
        handleChangeType,
        clearType,
        handleDeleteType,
        handlePickType,
        needStore,
        handleSelectAllTypes
      }
    },
  }
</script>

<style scoped lang="scss"></style>
