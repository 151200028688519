import { defineStore } from 'pinia'
import { assign } from 'lodash-es'
import { useNeedStore } from '@/store/needStore'
import { ref } from 'vue'

export const usePaginationStore = defineStore('needPaginationStore', () => {
  const paginationData = ref({
    isFirstPage: true,
    isLastPage: false,
    pageSize: 20,
    totalRow: 0,
    currentPage: 1,
    totalPage: 0,
  })
  const { setNeedSearchParams } = useNeedStore()

  const setPaginationData = (PaginationDataParams) => {
    assign(paginationData.value, PaginationDataParams)
    setNeedSearchParams(paginationData.value)
  }
  const handleCurrentPageChange = (curPage) => {
    const { searchNeed } = useNeedStore()
    setPaginationData({ currentPage: curPage })
    searchNeed()
  }
  const handleNextClick = () => {
    if (!paginationData.value.isFirstPage) {
      setPaginationData({ currentPage: paginationData.value.currentPage + 1 })
      const { searchNeed } = useNeedStore()
      searchNeed()
    }
  }
  const handlePrevClick = () => {
    if (!paginationData.value.isFirstPage) {
      setPaginationData({ currentPage: paginationData.value.currentPage - 1 })

      const { searchNeed } = useNeedStore()

      searchNeed()
    }
  }

  return {
    handlePrevClick,
    handleNextClick,
    handleCurrentPageChange,
    setPaginationData,
    paginationData,
  }
})
