import { defineStore } from 'pinia'
import { ref } from 'vue'
import search from '@/api/searchNeed'
import { assign } from 'lodash-es'
import { usePaginationStore } from '@/store/needStore/paginationStore'
import { Loading } from 'element-ui'

export const useNeedStore = defineStore('needStore', () => {
  const needStoreData = ref({
    startDate: '',
    endDate: '',
    searchValue: '',
    address: '',
    currentTypeName: '',
    currentTypeId: '',
    pageSize: 20,
    currentPage: 1,
    // minPrice: '',
    // maxPrice: '',
  })

  const needLists = ref([])

  const clearNeedLists = () => {
    needLists.value = []
  }

  const searchNeedLists = async (searchValue) => {
    if (searchValue !== undefined && searchValue !== null) {
      needStoreData.value.searchValue = searchValue
    }

    const res = await search(
      {
        start_date: needStoreData.value.startDate,
        end_date: needStoreData.value.endDate,
        name: needStoreData.value.searchValue,
        address: needStoreData.value.address,
        type_id: needStoreData.value.currentTypeId,
      },
      needStoreData.value.currentPage
    )
    if (res.status === 200) {
      console.log('res:  ', res)
      const { data } = res
      const { setPaginationData } = usePaginationStore()
      clearNeedLists()
      data.list.forEach((list) => {
        needLists.value.push(list)
      })
      setPaginationData({
        isFirstPage: data.firstPage,
        isLastPage: data.lastPage,
        totalPage: Math.ceil(data.total / 20),
      })
    } else {
      console.log(`searchNeed happened error: `, res.msg)
    }
    return true
  }

  const handleCityChange = (selectedCity) => {
    needStoreData.value.address = selectedCity
    if (selectedCity === '全部') {
      needStoreData.value.address = ''
    }
    console.log(needStoreData.value.address)
    searchNeed()
  }

  const setNeedSearchParams = (params) => {
    assign(needStoreData.value, params)
    console.log('needStoreData: ', needStoreData.value)
  }

  const searchNeed = (value) => {
    Promise.race([searchNeedLists(value), timeout(300)]).then((displayed) => {
      if (!displayed) {
        showSpinner()
      }
    })
  }
  const showSpinner = () => {
    const loadingInstance = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    console.log('please wait...')
    setTimeout(() => {
      loadingInstance.close()
    }, 1000)
  }
  const timeout = (delay, result) => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }

  return { needStoreData, searchNeed, setNeedSearchParams, needLists, handleCityChange }
})
