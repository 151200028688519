import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useNeedStore } from '@/store/needStore'
import { getClassifyList } from '@/api/getTypes'

export const useTypePickerStore = defineStore('needTypePickerStore', () => {
  const typePickerData = ref({
    TypeMaps: [],
    typeNames: [],
    typeIds: [],
    currentTypeId: '',
    currentTypeName: '',
  })
  const needStore = useNeedStore()
  const { searchNeed, setNeedSearchParams } = useNeedStore()
  const handleChangeType = async ({ type_id, type_name }) => {
    needStore.needStoreData.currentTypeId = type_id
    needStore.needStoreData.currentTypeName = type_name
    typePickerData.value.currentTypeId = type_id
    typePickerData.value.currentTypeName = type_name
    if (!typePickerData.value.typeNames.includes(type_name)) {
      typePickerData.value.typeNames.push(type_name)
      typePickerData.value.typeIds.push(type_id)
    }
    console.log(`handleChangeType, currentTypeId :${typePickerData.value.currentTypeId}`)
    setNeedSearchParams(typePickerData.value)
    searchNeed()
  }

  const clearType = () => {
    typePickerData.value.currentTypeName = ''
    typePickerData.value.currentTypeId = ''
    setNeedSearchParams(typePickerData.value)
    searchNeed()
  }

  const handleDeleteType = (typeName) => {
    typePickerData.value.currentTypeName = ''
    typePickerData.value.currentTypeId = ''
    const index = typePickerData.value.typeNames.findIndex((name) => name === typeName)
    typePickerData.value.typeNames.splice(index, 1)
    typePickerData.value.typeIds.splice(index, 1)
    setNeedSearchParams(typePickerData.value)
    searchNeed()
  }

  const handlePickType = (typeName, typeId) => {
    typePickerData.value.currentTypeId = typeId
    typePickerData.value.currentTypeName = typeName
    setNeedSearchParams(typePickerData.value)
    searchNeed()
  }

  const getTypeMaps = async () => {
    const res = await getClassifyList()
    if (res.status === 200) {
      const { data } = res
      typePickerData.value.TypeMaps = []
      data.map(({ type_id, type_name }) => {
        typePickerData.value.TypeMaps.push([type_id, type_name])
      })
    } else {
      console.log('getClassifyList happened error')
    }
  }

  getTypeMaps()

  const handleSelectAllTypes = () => {
    clearType()
    setNeedSearchParams(typePickerData.value)
    searchNeed()
  }


  return {
    typePickerData,
    handleChangeType,
    clearType,
    handleDeleteType,
    handlePickType,
    handleSelectAllTypes,
  }
})
