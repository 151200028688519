import request from './request'
import { isNull, omitBy } from 'lodash-es'

export default (inputs, pageNumber = 1) => {
  inputs = omitBy(inputs, (item) => item === '' || isNull(item))

  const data = {
    inputs: { ...inputs },
    pagination: {
      pageNumber: pageNumber,
      pageSize: 20,
    },
  }
  data.inputs.flag = 1
  return request({
    url: '/web/home/search/device',
    method: 'post',
    data: data,
  })
}
