<template>
  <NeedContainer :handleChangeCity="handleCityChange" :cityValue="regionStore.regionData.address">
    <div class="search-page">
      <div class="container">
        <div class="w-1200">
          <Search
            @searchDevice="handleSearchDevice"
            @searchNeed="searchNeed"
            :commandValue="'找需求'"
            :searchValue="needStore.needStoreData.searchValue"
            />
          <FilterForm />
          <NeedItems :needListData="needStore.needLists" />
          <Pagination
            @currentChange="handleCurrentPageChange"
            @nextClick="handleNextClick"
            @prevClick="handlePrevClick"
            :currentPage="paginationStore.paginationData.currentPage"
            :pageSize="paginationStore.paginationData.pageSize"
            :pageCount="paginationStore.paginationData.totalPage" />
        </div>
      </div>
    </div>
  </NeedContainer>
</template>

<script>
  import { usePaginationStore } from '@/store/needStore/paginationStore'
  import { useNeedStore } from '@/store/needStore'
  import Pagination from '@/components/pagination'
  import NeedItems from '@/pages/needList/listItems'
  import NeedContainer from '@/pages/needList/needContainer'
  import Search from '@/components/search'
  import FilterForm from '@/pages/needList/filterForm'
  import { onBeforeMount } from 'vue'
  import { useRouter, useRoute } from 'vue-router/composables'
  import { useRegionStore } from '@/store/regionStore'
   import { useTypePickerStore } from '@/store/needStore/typePickerStore'
  export default {
    components: {
      NeedContainer,
      Search,
      FilterForm,
      Pagination,
      NeedItems,
    },
    setup() {
      const { query } = useRoute()
      const router = useRouter()
      const needStore = useNeedStore()
      const { searchNeed, setNeedSearchParams } = useNeedStore()
      const paginationStore = usePaginationStore()
      const { handleCurrentPageChange, handleNextClick, handlePrevClick } = usePaginationStore()
      const paginationData = paginationStore.paginationData
      const { handleCityChange } = useRegionStore()
      const regionStore = useRegionStore()

      const handleSearchDevice = (searchValue = "") => {
        router.push({ name: 'deviceList', query: { searchValue } })
      }

      onBeforeMount(() => {
        // searchBar 跳转过来
        if (query) {
          const { type_id = '', type_name = '', searchValue = '' } = query
          const { handleChangeType } = useTypePickerStore()
          console.log(query)
          handleChangeType({ type_id, type_name })
          setNeedSearchParams({ searchValue: searchValue })
          searchNeed(searchValue)
        }
      })

      return {
        regionStore,
        needStore,
        handleSearchDevice,
        searchNeed,
        paginationStore,
        paginationData,
        handleCurrentPageChange,
        handleNextClick,
        handlePrevClick,
        handleCityChange,
      }
    },
  }
</script>

<style scoped lang="scss"></style>
